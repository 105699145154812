import { getCustomField } from "@/lib/bigcommerce/utils/bigcommerceCustomFieldsUtils";
import {
	Breadcrumb,
	Image,
	Prices,
	ProductData,
	ProductBulkPricing,
	ProductCategory,
	ProductCustomField,
	ProductOption,
	ProductVariant,
	ProductVariantOption,
	Video,
} from "../types";

const mapProduct = (data: any, remap?: boolean) => {
	if (!data || Array.isArray(data) || Object.keys(data).length < 1) {
		return undefined;
	}

	const node = data.node || data;

	const customFields: ProductCustomField[] =
		(node?.customFields?.edges || node?.custom_fields)?.map((field: any) => ({
			name: field.node?.name || field.name,
			value: field.node?.value || field.value,
		})) || [];

	const categoryNode = node?.categories?.edges?.length
		? node.categories.edges
		: undefined;

	const product = {
		id: node.entityId || node.id,
		createdAt: node.createdAt,
		updatedAt: node.updatedAt,
		path: node.path || node?.custom_url.url,
		sku: node.sku,
		name: node.name,
		description: node.description,
		categories: remap
			? node.categories
			: parseProductCategories(categoryNode) || node.categories.join(","),
		seo: {
			title: node.seo?.pageTitle || node.name,
			description: node.seo?.metaDescription,
			keywords: node.seo?.metaKeywords,
		},
		pricesIncTax: parseProductPrices(
			node.price_inc_tax || node.unitPrice || node.price
				? {
						price: node.price_inc_tax || node.unitPrice || node.price,
						basePrice: node.price_inc_tax || node.unitPrice || node.price,
						salePrice: node.price_inc_tax || node.unitPrice || node.price,
						unitPrice: node.price_inc_tax || node.unitPrice || node.price,
				  }
				: node.pricesIncTax,
		),
		pricesExTax: parseProductPrices(
			node.price_ex_tax || node.unitPrice || node.price
				? {
						price: node.price_ex_tax || node.unitPrice || node.price,
						basePrice: node.price_ex_tax || node.unitPrice || node.price,
						salePrice: node.price_ex_tax || node.unitPrice || node.price,
						unitPrice: node.price_ex_tax || node.unitPrice || node.price,
				  }
				: node.pricesExTax,
		),
		purchasability: {
			minQuantity: node.minPurchaseQuantity || node.order_quantity_minimum || 1,
			maxQuantity: node.maxPurchaseQuantity || node.order_quantity_maximum,
		},
		warranty: node.warranty,
		meta: node?.metafields?.edges.map((metafield: any) => metafield.node.value),
		customFields,
		variants: parseProductVariants(node),
		options:
			node?.productOptions?.edges.map((edge: any) => {
				return {
					...edge.node,
				} as ProductOption;
			}) || [],
		mpn: node?.mpn,
		brand: node?.brand,
		condition: node?.condition,
		availability: node?.availabilityV2?.status || node.availability,
		noAvailabilityMessage:
			node?.availabilityV2?.message || node.noAvailabilityMessage,
		plainTextDescription: node?.plainTextDescription,
		inventory: {
			...node?.inventory,
			aggregated: node?.inventory?.aggregated || undefined,
		},
		children: node?.children,
		siblings: node?.siblings,
		kitProducts: node?.kitProducts,
		media: remap
			? node.media
			: {
					images: Array.isArray(data.images)
						? data?.images
								?.map((image: any) => ({
									__type: "Image",
									id: image.id,
									description: image.description,
									sortOrder: image.sort_order,
									isThumbnail: image.is_thumbnail,
									url: {
										standard: image.url_standard,
										thumbnail: image.url_thumbnail,
										tiny: image.url_tiny,
										zoom: image.url_zoom,
									},
								}))
								.sort((a: Image, b: Image) => {
									return a.sortOrder < b.sortOrder ? -1 : 1;
								})
						: [{ url: { thumbnail: data?.images?.edges?.[0]?.node?.url } }],
					videos: data?.videos
						?.map((video: any) => ({
							__type: "Video",
							id: video.id,
							description: video.description,
							title: video.title,
							type: video.type,
							videoId: video.video_id,
							sortOrder: video.sort_order,
						}))
						.sort((a: Video, b: Video) => {
							return a.sortOrder < b.sortOrder ? -1 : 1;
						}),
			  },
		orderedQuantity: Number(node?.quantity),
		wishlistQuantity: Number(node?.quantity),
		shippedQuantity: Number(node?.quantity_shipped),
		kitQuantity: Number(node?.quantity),
		_type: "Product",
		productClass: "",
	} as ProductData;

	product.productClass = getCustomField(product, "ProductClass")?.value || "";

	return product;
};

const parseProductCategories = (data: any[]) => {
	if (!data) {
		return undefined;
	}
	return data.map(
		(edge) =>
			({
				name: edge.node.name,
				path: edge.node.path,
				defaultImage: edge.node.defaultImage?.url,
				altText: edge.node.defaultImage?.altText,
				breadcrumbs: edge.node.breadcrumbs.edges.map((edge: any) => {
					return {
						...edge.node,
					} as Breadcrumb;
				}),
			}) as ProductCategory,
	);
};

const parseProductPrices = (prices: any) => {
	if (!prices) {
		return undefined;
	}

	const parsePrice = (price: any) => {
		if (!price) {
			return undefined;
		}

		if (price.value !== undefined) return price;

		return {
			currencyCode: price.currencyCode,
			value: price.value || price,
		};
	};

	const parseBulkPricing = (bulkPricing: any) => {
		if (!bulkPricing) {
			return undefined;
		}

		return bulkPricing.map((bulkPrice: any) => {
			let type: any;
			let value: any;

			if (bulkPrice?.percentOff) {
				type = "percentage_discount";
				value = bulkPrice.percentOff;
			} else if (bulkPrice?.price) {
				type = "fixed_price_discount";
				value = bulkPrice.price;
			} else if (bulkPrice?.priceAdjustment) {
				type = "relative_price_discount";
				value = bulkPrice.priceAdjustment;
			}

			return {
				minimumQuantity: bulkPrice.minimumQuantity,
				maximumQuantity: bulkPrice.maximumQuantity,
				value,
				type,
			} as ProductBulkPricing;
		});
	};
	const parsedPrices = {
		price: parsePrice(prices.price),
		basePrice: parsePrice(prices.basePrice),
		salePrice: parsePrice(prices.salePrice),
		unitPrice: parsePrice(prices.unitPrice),
		saved: parsePrice(prices.saved),
		bulkPricing: parseBulkPricing(prices?.bulkPricing),
	} as Prices;

	return parsedPrices;
};

const parseProductVariants = (node: any) => {
	if (!node || !node?.variants?.edges) {
		return [];
	}

	const getBaseVariant = (data: any) => {
		return {
			entityId: data.node.entityId,
			sku: data.node.sku,
			image: data.node.defaultImage,
			isPurchasable: data.node.isPurchasable,
			pricesIncTax: parseProductPrices(data.node.pricesIncTax),
			pricesExTax: parseProductPrices(data.node.pricesExTax),
		};
	};

	//get the product variant option from the data node
	const getProductVariantOption = (data: any) => {
		const optionNodes =
			(data?.node?.productOptions?.edges as any[]).length === 0
				? undefined
				: data.node.productOptions.edges;

		if (!optionNodes) {
			return undefined;
		}

		return optionNodes.map((option: any) => {
			const optionValue = option.node.values.edges?.[0].node || undefined;

			if (!optionValue) {
				return undefined;
			}

			return {
				displayName: option.node.displayName,
				label: optionValue.label,
				isDefault: optionValue.isDefault,
				entityId: optionValue.entityId,
			} as ProductVariantOption;
		});
	};

	const getInventory = (data: any) => {
		return {
			...data.node.inventory,
		};
	};

	return (
		(node.variants.edges as any[])
			.map((edge: any) => {
				const inventory = getInventory(edge);

				return {
					...getBaseVariant(edge),
					name: "",
					options: getProductVariantOption(edge),
					inventory,
					_type: "ProductVariant",
				} as ProductVariant;
			})
			// remove any undefined values form the return
			.filter((variant: any) => variant && variant.options !== undefined)
	);
};

export default mapProduct;
