import { StoreRegion } from "@/lib/builder/builder.config";
import { useParams, usePathname } from "next/navigation";

const useStoreKeys = () => {
	// if (typeof window !== "undefined") {
	// 	const pathname = window.location.pathname.split("/");

	// 	return {
	// 		store: pathname[1],
	// 		lang: pathname[2],
	// 	};
	// }

	const { store, lang } = useParams<{ store: StoreRegion; lang: string }>();

	if (!store) {
		return {
			store: "global" as StoreRegion,
			lang: "",
		};
	}

	return { store, lang };
};

export default useStoreKeys;
